@import '~global/styles.scss';

.datePickerWrap {
  > div {
    width: 100%;
  }
}

.datePickerInput {
  font-family: $text-font;
  font-size: $font-size-medium;
  line-height: 24px;

  padding: 6px 12px;
  box-sizing: border-box;
  width: 100%;

  @include on-event() {
    box-shadow: $button-shadow;
  }
  &:hover:not([disabled]) {
    box-shadow: none;
  }
  border: 2px solid $blue-dark;
  border-radius: 4px;
}

.datePickerDay {
  font-size: 14px;
  font-weight: normal;

  color: $date-picker-color;
  background: $date-picker-day-background;

  border: 1px solid $date-picker-border;
  border-radius: 0;

  &:hover {
    border-radius: 0px;
  }

  &Disabled {
    background: $date-picker-day-background;
    font-weight: normal;

    border: 1px solid $date-picker-border;
    border-radius: 0px;
    opacity: 0.6;

    &:hover {
      background: $date-picker-day-background;
      font-weight: normal;

      border: 1px solid $date-picker-border;
      border-radius: 0px;
    }
  }

  &Today {
    color: $white;
    background-color: $date-picker-today-background-color;

    border: 1px solid $date-picker-today-border-color;
    border-radius: 0px;

    &:hover {
      color: $white;
      background-color: $date-picker-today-background-color;

      border: 1px solid $date-picker-today-border-color;
      border-radius: 0px;
    }
  }
  &Selected {
    color: $white;
    background-color: $blue-dark;

    border-radius: 0px;
    &:hover {
      border-radius: 0px;
    }
  }
}

.required {
  color: $red;
  margin-right: 3px;
}

.datePickerLabel {
  padding-bottom: 8px;
}
