:root {
  --mainDarkColor: darkblue;
  --arrowColor: lightblue;
}
/* color class for setting to main color */
._textmain {
  color: var(--mainDarkColor);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 40px;

  background: rgb(126, 227, 165);
  background: linear-gradient(
    0deg,
    rgba(126, 227, 165, 1) 0%,
    rgb(226, 255, 224) 64%
  );
}
/* ----------------------------------------------- */
/* -------------- HEADER COMPONENT --------------- */
.telinet-title-img {
  max-width: 550px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60vw;
}
.header-title {
  font-weight: 800;
  font-family: 'EnzoWeb-Bold';
  color: #0a416e;
  font-size: 42px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .container {
    padding: 20px !important;
    overflow-x: hidden;
  }
  .telinet-title-img {
    max-width: 80%;
  }

  .header {
    max-width: 100%;
    /*padding: 0 20px;*/
  }

  .header-title {
    font-size: 24px;
    text-align: center;
    white-space: normal;
  }

  .header-text {
    font-size: 1.2em !important;
    text-align: center !important;
  }

  .header-image {
    max-width: 175px !important;
    height: auto;
  }

  .checkboxes label._textmain {
    gap: 8px !important;
  }

  .dotted-checkbox {
    margin-right: 0.2rem !important;
  }
}
.header-text {
  font-size: 1.6em;
  font-weight: 500;
  color: #0a416e;
  text-align: left;
}
.header-image {
  max-width: 220px;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.checkboxes label._textmain {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 15px;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Avenir-Next';
  color: #0a416e;
  /*white-space: nowrap;*/
}

@media (max-width: 767px) {
  .checkboxes label._textmain {
    white-space: break-spaces;
    font-size: 16px;
  }
}

.dotted-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

/* ----------------------------------------------- */
/* ------------- ELAVTAL COMPONENT --------------- */
.elavtal-main {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  flex-direction: row;
  gap: 40px;
}

@media (min-width: 768px) and (max-width: 820px) {
  .elavtal-main {
    gap: 15px;
  }
}

@media (max-width: 767px) {
  .elavtal-main {
    flex-direction: column;
  }
}

.elavtal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.elavtal-box {
  background-color: white;
  border: 2px solid var(--mainDarkColor);
  border-radius: 28px;
  padding: 8px;
  transition: all 0.6s ease;
  position: relative;
  overflow: hidden;
  min-height: 280px;
  max-width: 380px;
}

.elavtal-box h1 {
  font-size: 1.4em;
}

@media (max-width: 767px) {
  .elavtal-box h1 {
    font-size: 1.2em;
  }
}

.hidden-elavtal-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.hidden-elavtal-content.visible {
  max-height: 1000px;
}

.hidden-elavtal-content div {
  padding-bottom: 1.5rem;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.elavtal-main p {
  margin: 0.5rem 0;
  font-size: 20px;
}

@media (max-width: 767px) {
  .elavtal-main p {
    font-size: 16px;
  }
}

.choosen_agree_checkbox_text {
  font-family: 'EnzoWeb-Bold';
  font-size: 18px;
  color: #0a416e;
}

@media (max-width: 767px) {
  .choosen_agree_checkbox_text {
    font-size: 16px;
  }
}

.elavtal-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  background-color: white;
  border: 1px solid var(--mainDarkColor);
  position: relative;
  outline: none;
}

.elavtal-option:checked {
  background-color: white;
  border-color: var(--mainDarkColor);
}

.elavtal-option:checked::after {
  content: '✔';
  color: #2cc0d0;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dotted-arrow {
  display: block;
}

.month-selection {
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .month-selection {
    margin-top: 20px;
  }
}

@media (min-width: 924px) {
  .month-selection {
    padding-left: 70px;
    padding-right: 40px;
  }
}

/*@media (max-width: 768px) {
  .month-selection {
    padding-left: 0;
    padding-right: 0;
  }
}*/

@media (max-width: 767px) {
  .month-selection {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.month-label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.month-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  background-color: white;
  border: 1px solid var(--mainDarkColor);
  position: relative;
}

.month-checkbox:checked {
  background-color: var(--mainDarkColor);
  border-color: var(--mainDarkColor);
}

.month-checkbox:checked::after {
  content: '✔';
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.boxtext-elon {
  margin-top: 15% !important;
}
.secret-boxtext-elon-border {
  border-bottom: solid 0.1px var(--mainDarkColor);
  padding-bottom: 16px;
  margin-top: 5% !important;
}

/* ----------------------------------------------- */
/* ------------- ChooseAvtal COMPONENT --------------- */
.choose-avtal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
}

.choose-avtal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
}

.choose-avtal-checkbox {
  cursor: pointer;
  background-color: white;
  border: 1px solid var(--mainDarkColor);
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

label {
  display: flex;
  align-items: center;
}

label span {
  margin-right: 5px;
}

@media (max-width: 767px) {
  label span {
    margin-right: 10px;
    font-size: 16px;
    white-space: nowrap;
  }
}

/*.sign-elavtal {
  width: 160px;
  height: 40px;
  background: white;
  border: 1px solid var(--mainDarkColor);
  border-radius: 18px;
}*/
.sign-elavtal {
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: var(--mainDarkColor);
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 14px 24px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 150px;
}

.sign-elavtal:hover {
  background-color: #f3f4f6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.sign-elavtal:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
}

.sign-elavtal:active {
  background-color: #edeff2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.sign-elavtal:before {
  display: none;
}

.sign-elavtal:-webkit-details-marker {
  display: none;
}
/* ----------------------------------------------- */
/* ------------- Badges COMPONENT --------------- */
.badges-container {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 40px;
  padding: 20px;
}

.badge {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
}

@media (max-width: 767px) {
  .badge {
    width: 75px;
    height: 75px;
  }
}
/* ----------------------------------------------- */
/* ------------- CalculatePriceBox COMPONENT --------------- */
.calculate-price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.calculate-price-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 820px) {
  .calculate-price-info {
    width: 90%;
  }
}

.calculate-price-label-wrapper {
  display: flex;
  flex-direction: row;
  height: 10px;
  width: 350px;
  margin-right: 10px;
  justify-content: space-between;
}

@media (max-width: 820px) {
  .calculate-price-label-wrapper {
    width: 100%;
  }
}

.smallLabels-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.calculate-price-label-wrapper-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.redlabel {
  color: rgb(236, 59, 59);
}

.boldlabel {
  font-weight: bold;
}

.smalllabel,
.smalllabel2 {
  font-size: 100%;
  flex: 1 1 auto;
}

@media (max-width: 820px) {
  .smalllabel,
  .smalllabel2 {
    font-size: 90%;
  }
}

.calculate-price-label-wrapper .calculate-price-label .price-original {
  text-decoration: line-through;
  opacity: 0.5;
}

.calculate-price-label-wrapper .calculate-price-label .price-new {
  color: red;
  font-weight: bold;
}

@media (min-width: 1025px) {
  .calculate-price-label-wrapper .calculate-price-label .price-new {
    white-space: nowrap;
  }
}

@media (max-width: 500px) {
  .calculate-price-label-wrapper .calculate-price-label .price-new {
    white-space: nowrap;
  }
}

/*
@media (max-width: 768px) {
  .calculate-price-label-wrapper-small {
    flex-direction: column;
    align-items: flex-start;
  }

  .smalllabel,
  .smalllabel2 {
    width: 100%;
    font-size: 90%;
  }
}

.calculate-price-label-wrapper p,
.calculate-price-label-wrapper-small p {
  margin: 0;
}

.calculate-price-label {
  margin: 0;
} */

/* ------------------------------------------------------ */
/* ------------- ChangeAreaCode COMPONENT --------------- */
.change-areacode-wrapper {
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
}
.change-area-text {
  font-size: 18px;
  color: var(--mainDarkColor);
  font-weight: 700;
  /*border-bottom: 0.1px solid var(--mainDarkColor);
  padding: 2px 10px 10px;*/
}

@media (max-width: 767px) {
  .change-area-text {
    font-size: 16px;
  }
}
.change-area-helptext {
  font-size: 16px;
  color: var(--mainDarkColor);
  font-weight: 400;
}
.change-input-container {
  display: flex;
  gap: 10px;
  color: var(--mainDarkColor);
  justify-content: flex-start;
  align-items: flex-start;
}

.change-input {
  background-color: #fefefe;
  padding: 1rem;
  font-size: 1rem;
  width: 550px;
  border-radius: 4px;
  color: var(--mainDarkColor);
  border: 0.1px solid black;
  cursor: pointer;
}

@media (max-width: 600px) {
  .change-input {
    width: 250px;
  }
}

.change-input:focus {
  outline-color: var(--mainDarkColor);
  outline-style: outset;
}

.change-input::-webkit-inner-spin-button,
.change-input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.change-input[type='number'] {
  -moz-appearance: textfield !important;
}

.helpinput-error {
  border: 0.5px solid rgb(255, 61, 61);
  border-radius: 4px;
  color: rgb(255, 61, 61);
}
/*.helpinput-error::placeholder {
  color: rgb(255, 61, 61);
}*/
.helpinput-error:focus {
  outline-color: rgb(122, 33, 33);
  outline-style: inset;
}
.helptext-error {
  color: rgb(255, 61, 61);
}
.helptext-success {
  color: var(--mainDarkColor);
}

.change-input-container button {
  border: 0.1px solid var(--mainDarkColor);
  color: var(--mainDarkColor);
  background: white;
  border-radius: 7px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 16px;
  padding: 0.6em 1.4em;
}

button:hover {
  opacity: 0.9;
  cursor: pointer;
}

.faButtonsWrapper {
  display: flex;
  z-index: 3;
  gap: 9px;
  position: fixed;
  bottom: 15px;
  left: 20px;
}
.faMinusButton,
.faPlusButton {
  width: 100px;
  height: 50px;
  background: #0c3d65;
  color: #fff;
  border-radius: 7px;
  border: 1px solid skyblue;
  font-size: 16px;
}
/* ------------------------------------------------------ */
/* ------------- SpinnerCopy COMPONENT --------------- */
.spinnercopy {
  margin: 2px auto 0;
  text-align: center;
}

.spinnercopy > div {
  width: 8px;
  height: 8px;
  background-color: var(--mainDarkColor);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinnercopy .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinnercopy .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .elavtal-box {
    padding: 16px;
    max-width: 80%;
    font-size: 1.25em;
  }
  .smallLabels-wrapper {
    width: 115%;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  .calculate-price-label-wrapper {
    width: 440px;
  }
}

body.no-scroll {
  overflow: hidden !important;
}
