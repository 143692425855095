@import '~global/styles';
.inputWrap {
  margin-bottom: 16px;
  width: 100%;
  .required {
    color: $red;
    margin-left: 3px;
  }
  div {
    margin-bottom: 8px;
  }
  .areaInput {
    height: 25px;
    border: 2px solid $blue-dark;
    border-radius: 5px;
    font-size: 14px;
    outline: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 12px;

    &Large {
      height: 40px;
    }

    &:focus {
      &.outline {
        box-shadow: $active-outline;
      }
    }
  }

  .error {
    border: 2px solid $red;

    &:outline {
      box-shadow: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
}
