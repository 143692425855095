@import '~global/styles';

.button {
  font-family: $title-font;
  font-size: $font-size-large;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;

  padding: 10px 25px;

  color: $white;
  background-color: $blue-dark;

  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: none;

  @include on-event() {
    background-color: $hover-button-dark-blue;
    cursor: pointer;
  }

  @include respond-to('medium') {
    font-size: $font-size-xs;

    padding: 10px;
    min-width: 100px;
  }

  &:disabled {
    opacity: 0.65;
  }
  &.primary {
    border: solid 2px $blue-light;
    box-shadow: none;
  }
  &.next {
    line-height: 24px;
    padding: 6px 12px;
    border-radius: 4px;
    box-shadow: none;
    border: solid 2px $blue-dark;

    @include on-event() {
      box-shadow: $button-shadow;
    }
    &:hover:not([disabled]) {
      box-shadow: none;
    }
  }
  &.back {
    background-color: $white;
    color: $blue-dark;

    line-height: 24px;

    padding: 6px 12px;

    border: solid 2px $blue-dark;
    border-radius: 4px;
    box-shadow: none;

    @include on-event() {
      box-shadow: $button-shadow;
      background-color: $hover-button-grey;
    }
  }
  &.submit {
    background-color: $green;
    height: 40px;

    @include on-event() {
      box-shadow: $button-shadow;
      background-color: $hover-button-green;
    }
    &:hover:not([disabled]) {
      box-shadow: none;
    }
  }
}
.leadsStyles {
  font-size: 20px;
  padding: 14px 28px;
}
.centerAlign {
  text-align: center;
}
.submitButtonWrap {
  height: 40px;
}
.leadsStylesButton {
  font-size: 20px;
  padding: 14px 28px;
  background-color: $light-green;
  color: $blue-dark;
  outline: none;
  box-shadow: none;
  &.primary {
    border: solid 2px $leads-blue-border-color;
    box-shadow: none;
  }
  @include on-event() {
    box-shadow: $button-shadow;
    background-color: $hover-button-green;
    cursor: pointer;
    outline: none;
    box-shadow: none;
  }

  @include respond-to('medium') {
    font-size: $font-size-xs;
    padding: 10px 20px;
    min-width: 120px;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .submitButtonWrap {
    position: absolute;
    bottom: 20px;
  }
}
