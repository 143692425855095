@import '~global/styles';

.container {
  margin: 10px 0;
}

.message {
  margin-top: 10px;
  text-align: center;
}

.uploadImage {
  flex-direction: column;
  margin-top: 10px;
}

.mutedText {
  font-size: 12px;
  color: $light-grey;
}

.bankIdQrWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }

  canvas {
    border: 1px solid #ccc;
  }
}
