@import '~global/styles.scss';

.perkWrap {
  height: 117px;
  width: 117px;
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0px;
  }
  @include respond-to('small') {
    padding: 0;
  }
  @include respond-to('medium') {
    height: 72px;
    width: 72px;
  }
  .perk {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    background-image: url('../../global/images/bg-perk.png');
    background-size: 100%;
    background-repeat: no-repeat;

    &Text {
      font-family: $title-font;
      font-size: $font-size-small;
      text-align: center;
      color: $white;
      line-height: 14px;
      overflow-wrap: break-word;

      margin: 0;
      padding: 0;

      @include respond-to('medium') {
        font-size: $font-size-xxs;
        line-height: 12px;
      }

      &Lg {
        font-family: $text-font;
        font-size: $font-size-xl;
        line-height: 30px;
        color: $white;
        text-align: center;

        margin: 0;
        overflow-wrap: break-word;

        @include respond-to('medium') {
          font-size: $font-size-xs;
          line-height: 20px;
        }
      }
    }

    &Circle {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: $blue-dark;
      border-radius: 50%;

      &Light {
        background-color: $blue-light;
      }
    }

    &Circle:not(.alignToTop) {
      justify-content: center;
    }

    .alignToTop {
      justify-content: end;
    }
  }
}
.perkContentWrap {
  padding: 20px 17px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.errorMessage {
  color: $white;
  font-weight: $bold;
}
