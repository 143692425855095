:root {
  --body-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  --code-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--code-font-family);
}

.Toastify__toast-container {
  padding: 10px !important;
  top: 35% !important;
}
.Toastify__toast {
  width: 450px !important;
  height: 125px !important;
  margin-top: 10px !important;
  margin-left: -50px !important;
}
.Toastify__toast-body {
  font-size: 1.4rem;
  padding-left: 30px !important;
  font-weight: bold;
}
.Toastify__progress-bar {
  height: 7px !important;
}
.Toastify__close-button svg {
  width: 20px !important;
  height: 20px !important;
}

@media (max-width: 576px) {
  .Toastify__toast {
    width: 100% !important;
    height: 75px !important;
    margin-left: 0px !important;
  }
  .Toastify__toast-body {
    font-size: 1rem;
    padding-left: 20px !important;
  }
}
@media (max-width: 768px) {
  .Toastify__toast {
    width: 100% !important;
    height: 75px !important;
    margin-left: 0px !important;
  }
  .Toastify__toast-body {
    font-size: 1rem;
    padding-left: 20px !important;
  }
}
