@import '~global/styles';

.videoWrap {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    box-sizing: border-box;
  }
  iframe {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    @media (max-width: 575px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .videoDesctiption {
    flex-basis: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    @media (max-width: 575px) {
      padding: 0 15px;
    }
  }
}
