@import '~global/styles';
@import '../MediaMarkt/styles.module.scss';

.prices {
  background: url(../../global/images/wave-bg.png) right -17px;
}
.pricesWrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px;
  box-sizing: border-box;
  @media (min-width: 576px) and (max-width: 992px) {
    // padding: 30px 30px;
  }

  div {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    @include respond-to('large') {
      flex-basis: 50%;
    }
  }
  > div:last-of-type {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 15%;

    @media (max-width: 1200px) {
      flex-basis: 18%;
    }
  }
  @include respond-to('large') {
    flex-direction: column;
  }

  .comparativePriceWrap {
    @include respond-to('large') {
      margin: 5px 0;
    }
  }
}
