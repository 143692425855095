@import '~global/styles';

.root {
  .twoColumns {
    display: flex;
    > div:nth-of-type(1) {
      flex-basis: 50%;
      padding-right: 15px;
    }
    > div:nth-of-type(2) {
      flex-basis: 50%;
      padding-left: 15px;
    }
    .radioButtons {
      padding: 10px;
    }
    &RadioButton {
      padding: 10px;
      display: flex;
      > div:nth-of-type(2) {
        margin-left: 30px;
      }
    }
  }
  .localStorageErr {
    display: flex;
    justify-content: center;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}

@media (max-width: 992px) {
  .root {
    .twoColumns {
      flex-direction: column;
      > div:nth-of-type(1) {
        padding-right: 0px;
      }
      > div:nth-of-type(2) {
        padding-left: 0px;
      }
    }
    .messages {
      margin-bottom: 50px;
    }
  }
}
@include respond-to('medium') {
  .root {
    .twoColumns {
      flex-direction: column;
      > div:nth-of-type(2) {
        padding-left: 0;
      }
    }
  }
}
