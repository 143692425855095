@import '~global/styles';

.title {
  color: $blue-dark;
  font-family: $title-font;

  &Light {
    color: $blue-light;
  }

  &Small {
    font-size: $font-size-xl;
    margin: 0 0 8px;
    @include respond-to('large') {
      font-size: $font-size-xxl;
    }
    &Static {
      font-size: $font-size-large;
    }
  }
  &Medium {
    font-size: $font-size-medium-secondary;
    margin: 0 0 10px;
  }
  &Large {
    font-size: $font-size-xxxl;
    margin: 16px 0 8px;
    @include respond-to('large') {
      font-size: $font-size-xxl;
    }
  }

  &Center {
    text-align: center;
  }
}
