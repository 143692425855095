@import '~global/styles';

.headerWrapper {
  background-color: $blue-light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .leftTitle {
    flex: 1;
    text-align: left;
  }

  .centerContainer {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalTextInfo {
    font-size: 16px;
    font-weight: bold;
  }

  .buttonsWrap {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    svg {
      color: $blue-dark;
      cursor: pointer;
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
}
