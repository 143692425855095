@import '~global/styles';

.text {
  font-size: $font-size-small;
  color: $blue-dark;
}

.small {
  font-size: $font-size-small;
}

.medium {
  font-size: $font-size-medium;
}

.mediumSecondary {
  font-size: $font-size-medium-secondary;
}

.large {
  font-size: $font-size-large;
}

.xl {
  font-size: $font-size-xl;
}

.bold {
  font-weight: $bold;
}

.primary {
  color: $blue-dark;
}

.secondary {
  color: $blue-light;
}

.white {
  color: $white;
}

.error {
  color: $red;
}
