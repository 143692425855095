@import '~global/styles';
@import '../MediaMarkt/styles.module.scss';

.historyWrap {
  padding: 30px 15px;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;

  @include respond-to('large') {
    flex-direction: column;
  }

  .historyDescription {
    width: 50%;
    margin-right: 15px;

    @include respond-to('large') {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    .historyParagraph {
      @include respond-to('large') {
        font-size: $font-size-medium;
      }
    }
  }
  .historyChart {
    width: 50%;
    @include respond-to('large') {
      width: 100%;
    }

    /*div {
      padding-left: 25px;
    }*/
  }
}
