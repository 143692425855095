@import '~global/styles';

.feesAndTaxes {
  max-width: 380px;
  box-sizing: border-box;

  div:nth-of-type(6) {
    @include respond-to('large') {
      padding: 10px 5px;
    }
  }

  @media (max-width: 992px) {
    padding: 0 15px;
  }

  @include respond-to('large') {
    max-width: 100%;
  }
  .title {
    margin-bottom: 20px;
    @include respond-to('large') {
      font-size: $font-size-medium-secondary;
      margin-bottom: 10px;
      margin-top: 0;
    }
  }
}
