@import '~global/styles';

.storeWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:nth-of-type(2) {
    select {
      width: 33%;
    }
  }
  .storeTitle {
    padding: 30px 0;
    margin-bottom: 8px;
  }
}
