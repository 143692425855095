@import '~global/styles';

.readMoreWrap {
  text-align: center;
  margin-bottom: 20px;
  display: none;
  @include respond-to('medium') {
    display: block;
  }
  p {
    margin: 0 0 5px 0;
  }
  img {
    width: 20px;
  }
  .downArrow {
    cursor: pointer;
  }
}
