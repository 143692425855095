@import '~global/styles.scss';

.wrap {
  padding: 20px;
  box-sizing: border-box;

  background-color: $blue-dark;
  color: $blue-light;
  @include respond-to('large') {
    padding: 20px;
  }

  &Light {
    padding: 40px 30px;
    @include respond-to('large') {
      padding: 20px;
    }

    background-color: $blue-light-transparent;
    color: $blue-dark;
  }

  @include respond-to('large') {
    padding: 20px;
  }
}
