.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 30px;
  justify-content: center;
  margin: 30px;

  &.vertical {
    flex-direction: column;
    margin-top: 10px;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .column1 {
    //width: 35%;
    align-items: flex-end;
  }

  .column2 {
    width: 65%;
    align-items: flex-start;
  }
}
