@font-face {
  font-family: 'Avenir-Next';
  src: url('fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Avenir-NextBold';
  src: url('fonts/AvenirNext-Bold.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'EnzoWeb-Light';
  src: url('fonts/EnzoWeb-Light.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'EnzoWeb-LightItalic';
  src: url('fonts/EnzoWeb-LightItalic.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'EnzoWeb-Medium';
  src: url('fonts/EnzoWeb-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'EnzoWeb-MediumItalic';
  src: url('fonts/EnzoWeb-MediumItalic.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'EnzoWeb-Bold';
  src: url('fonts/EnzoWeb-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'EnzoWeb-BoldItalic';
  src: url('fonts/EnzoWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
}

$title-font: 'EnzoWeb-Bold';
$text-font: 'Avenir-Next';
$bold: bold;
