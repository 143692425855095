@import '~global/styles';
.iconWrap {
  border-radius: 50%;

  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  &Active {
    background-color: $blue-light;
  }
  &NotActive {
    background-color: $icon-grey;
  }

  .icon {
    color: $white;
  }
}
