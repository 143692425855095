@import '~global/styles';

.title {
  margin-bottom: 20px;
  @include respond-to('large') {
    font-size: $font-size-medium-secondary;
    margin-bottom: 10px;
    margin-top: 0;
  }
}
.firstParagraph {
  @include respond-to('large') {
    font-size: $font-size-medium-secondary;
  }
}
.secondParagraph {
  margin-top: 16px;
  font-size: $font-size-medium-secondary;
  @include respond-to('large') {
    font-size: $font-size-medium;
  }
}
