@import '~global/styles';

.dropDownWrap {
  select {
    font-family: $text-font;
    font-size: $font-size-medium;
    color: $grey;

    width: 100%;
    height: 40px;

    margin: 0;
    padding: 5px;
    background-color: $white;
    border: 2px solid $blue-dark;
    border-radius: 0.25em;
    box-shadow: $input-shadow;
    outline: none;

    .emptyOption {
      display: none;
    }
  }
  &Center {
    text-align: center;
  }
}
