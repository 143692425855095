@import '~global/styles.scss';

.imageWrap {
  img {
    max-width: 100%;
    height: auto;
  }
  &Center {
    text-align: center;
  }
}
