@import '~global/styles';

.uploadPhotoWrapper {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  height: 50px;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}

input {
  font-size: $font-size-medium;
}

.hidden {
  display: none;
}

.chooseFile {
  border-radius: 3px;
  padding: 0px 10px;
  outline: none;
  cursor: pointer;
  background: $choose-file;
  border: 1px solid $choose-file-background;
  color: $black;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
