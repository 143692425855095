@import '~global/styles';
.notFoundHeader {
  padding: 30px;
  text-align: center;
}
.notFoundTextWrap {
  text-align: center;
  background-color: $blue-dark;
  height: calc(100vh - 112.6px);
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-family: EnzoWeb-Bold;
    font-size: 58px;
    line-height: 68px;
  }
}
