@import '~global/styles';

.navigationWrapper {
  align-items: center;
  justify-content: center;
  z-index: 2;

  .progresLine {
    width: 42%;
    height: 1px;
    background-color: $icon-grey;
    margin-left: 26.5%;
    position: absolute;
    top: 81px;
    z-index: 1;
    .progressLineCompleted {
      height: 1px;
      width: 50px;
      background-color: $blue-light;
    }
  }
  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    .step {
      z-index: 2;
    }
  }
}
