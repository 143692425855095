@import '~global/styles';

.infoPerks {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @include respond-to('small') {
    padding: 0;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 0;
    margin-bottom: 20px;
  }
  @media (min-width: 575px) and (max-width: 992px) {
    padding: 0 70px;
    margin: 20px 0;
  }

  .clickblePerk {
    cursor: pointer;
    margin-right: 10px;
  }
}
