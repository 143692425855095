@import '~global/styles';

.securityNumberWrap {
  display: flex;
  flex-direction: column;
  .inputWrap {
    margin-top: 16px;
    input {
      width: 99%;
    }
  }
  .bottomTextWrap {
    margin-top: 20px;
  }
  .existingUserMessage {
    color: $red;
    margin-bottom: 15px;
  }
}
