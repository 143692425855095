@import '~global/styles.scss';

$toggle-width: 66px;

.toggleSwitch {
  display: flex;

  .switchCheckBox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .switchLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: $red;
    border-radius: 3px;
    position: relative;
    transition: background-color 0.2s;
    transition: 0.3s;
    left: 3px;
    width: $toggle-width;
    height: calc(#{$toggle-width}/ 2);

    .switchButton {
      content: '';
      position: absolute;
      left: 4px;
      border-radius: 3px;
      transition: 0.2s;
      background: $white;
      box-shadow: $toggle-switch-shadow;
      transition: 0.3s;
      right: 5px;
      width: calc(#{$toggle-width}/ 2.2);
      height: calc(#{$toggle-width}/ 2.5);
    }
  }

  .active {
    background: $green;
    .switchOnIcon {
      position: absolute;
      left: 10px;
      bottom: 7px;
      color: $white;
    }
  }
  .processing,
  .disabled {
    background: $light-grey;
  }
  .switchOffIcon {
    position: absolute;
    right: 10px;
    bottom: 7px;
    color: $white;
    width: 16px;
    height: 16px;
  }
  .switchCheckBox:checked + .switchLabel .switchButton {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
}
