@import '~global/styles';

.inputsWrap {
  margin-top: 45px;
}
.twoColumns {
  display: flex;
  > div:nth-of-type(1) {
    flex-basis: 50%;
    padding-right: 15px;
  }
  > div:nth-of-type(2) {
    flex-basis: 50%;
    padding-left: 15px;
  }
}
.twoColumns {
  display: flex;
  > div:nth-of-type(1) {
    flex-basis: 50%;
    padding-right: 15px;
  }
  > div:nth-of-type(2) {
    flex-basis: 50%;
    padding-left: 15px;
  }
  &RadioButton {
    display: flex;
    > div:nth-of-type(2) {
      margin-left: 30px;
    }
  }
  .sameAddressWrap,
  .movingInWrap {
    > div:nth-of-type(1) {
      padding: 20px 0;
    }
  }
  .movingInWrap {
    padding-left: 15px;
  }
}
@media (max-width: 992px) {
  .twoColumns {
    flex-direction: column;
    > div:nth-of-type(1) {
      padding-right: 0px;
    }
    > div:nth-of-type(2) {
      padding-left: 0px;
    }
  }
}
@include respond-to('medium') {
  .twoColumns {
    flex-direction: column;
    > div:nth-of-type(2) {
      padding-left: 0;
    }
  }
}
