@import '~global/styles';

.successWrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background-image: url(../../../../global/images/congratilations-img.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 341px;
  width: 100%;

  @media (max-width: 556px) {
    min-height: 130px;
  }

  @media (min-width: 557px) and (max-width: 992px) {
    min-height: 207px;
  }
  .message {
    margin-bottom: 20px;
  }
}

.contractNext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 80px;

  @media (max-width: 992px) {
    align-items: center;
    padding: 30px 30px 0 30px;
  }

  .imageWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 20%;
    justify-content: center;

    @media (max-width: 992px) {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .imageDescription {
      margin-top: 5px;
    }
  }

  .description {
    flex-basis: 50%;
    padding: 0px 0px 0 180px;
    @media (max-width: 992px) {
      padding: 0px;
    }
  }

  .descsriptionTitle {
    padding-bottom: 20px;
    @media (max-width: 992px) {
      font-size: 22px;
    }
  }

  .descriptionText {
    @media (max-width: 992px) {
      font-size: 13px;
    }
  }
}
