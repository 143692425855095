@import '~global/styles';

.priceWrap {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $white;
  padding: 5px;
  div:first-of-type {
    flex-basis: 76%;
  }
  div:nth-of-type(2) {
    display: flex;
    justify-content: flex-end;
    flex-basis: 24%;
  }

  p {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 5px;
  }
  .priceValueWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
