@import '~global/styles';

.stepCompleted {
  div {
    background-color: $white;
    border: solid 1px $blue-light;
    svg {
      color: $blue-light !important;
    }
  }
  .line {
    height: 1px;
    position: absolute;
    top: 81px;
    width: 60px;
    background-color: $icon-grey;
    border: none;
    z-index: -1;
    @include respond-to('medium') {
      width: 50px;
      top: 78px;
    }
  }
}

.stepSelected {
  div {
    background-color: $blue-light;
  }
}
.line {
  height: 1px;
  position: absolute;
  top: 81px;
  width: 60px;
  background-color: $icon-grey;
  z-index: 1;
  @include respond-to('medium') {
    width: 50px;
    top: 78px;
  }
}

.step {
  width: 60px;
  @include respond-to('medium') {
    width: 50px;
  }
  .iconWrap {
    background-color: transparent;
    padding-left: 10px;
    border: none;
    svg {
      z-index: 2;
    }
    .modalStepIcon {
      @include respond-to('medium') {
        width: 30px;
        height: 30px;
      }
      svg {
        @include respond-to('medium') {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
  border: none;
  .passedStepLine {
    background-color: $blue-light;
  }
}
