@import '~global/styles';
.modalWrapper {
  display: block;
  position: fixed;
  z-index: 1050;

  min-width: 75%;
  min-height: 40%;

  top: 80px;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 30px;

  overflow-x: hidden;
  overflow-y: auto;
  .modal {
    position: relative;
    width: auto;
    z-index: 3;

    margin-left: auto;
    margin-right: auto;

    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);

    background: $white;

    border-radius: 3px;
    border: solid 1px $blue-dark;
    cursor: auto;

    @media (max-width: 1024px) {
      max-width: 80%;
    }
    @media (min-width: 1025px) {
      width: 50%;
    }
  }

  .modalContent {
    text-align: left;
    background-color: $white;
    padding: 20px;
  }
}

.modalFadeShow {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: $black;
  opacity: 0.5;
}
