@import '~global/styles';

.inputsWrap {
  margin-top: 45px;
  .twoColumns {
    display: flex;
    > div:nth-of-type(1) {
      flex-basis: 50%;
      padding-right: 15px;
    }
    > div:nth-of-type(2) {
      flex-basis: 50%;
      padding-left: 15px;
    }
  }
  .twoColumns {
    display: flex;
    > div:nth-of-type(1) {
      flex-basis: 50%;
      padding-right: 15px;
    }
    > div:nth-of-type(2) {
      flex-basis: 50%;
      padding-left: 15px;
    }
    &RadioButton {
      display: flex;
      > div:nth-of-type(2) {
        margin-left: 30px;
      }
    }
  }
  .sameAddressWrap,
  .movingInWrap {
    margin-top: 20px;
    > div:nth-of-type(1) {
      padding-bottom: 20px;
    }
  }
  .sameAddressWrap {
    @media (min-width: 821px) {
      > div:nth-of-type(2) {
        padding-bottom: 30px;
      }
    }
  }

  @media (max-width: 992px) {
    .twoColumns {
      flex-direction: column;
      > div:nth-of-type(2) {
        padding-left: 0px;
      }
    }
  }
  @include respond-to('medium') {
    .twoColumns {
      flex-direction: column;
      > div:nth-of-type(2) {
        padding-left: 0;
      }
    }
  }
}

.error {
  color: $red;
  margin: 15px 0 16px;
}
