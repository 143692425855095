@import './colors.scss';

// Active, focus and hover events
@mixin on-event($self: false) {
  @if $self {
    &,
    &:active,
    &:focus,
    &:hover:not([disabled]) {
      @content;
    }
  } @else {
    &:active,
    &:focus,
    &:hover:not([disabled]) {
      @content;
    }
  }
}
