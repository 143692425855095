@mixin respond-to($breakpoint) {
  @if $breakpoint == 'small' {
    @media (max-width: 320px) {
      @content;
    }
  } @else if $breakpoint == 'medium' {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'large' {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'extra-large' {
    @media (max-width: 1400px) {
      @content;
    }
  }
}
