@import '~global/styles.scss';

.widgetContainer {
  display: flex;
  justify-content: center;
  color: $light-grey;

  .status {
    display: flex;
    align-items: center;
    border-right: 2px solid $blue-dark;
    padding-right: 30px;

    div {
      padding-left: 4px;
    }
  }

  .details {
    padding-left: 30px;

    > div {
      display: flex;
      align-items: center;

      > div {
        padding: 0 4px;
      }
    }
  }

  @include respond-to('medium') {
    div {
      font-size: 11px;
    }

    .status {
      padding-right: 5px;
    }

    .details {
      padding-left: 5px;
    }
  }

  @media (max-width: 350px) {
    div {
      font-size: 8px;
    }
  }
}
