@import '~global/styles';

.brandsWrap {
  display: flex;
  align-items: center;

  div:nth-of-type(1) {
    padding-right: 20px;
  }
  div:nth-of-type(2) {
    padding: 20px;
  }
  div:nth-of-type(4) {
    padding-left: 20px;
  }

  img {
    width: 150px;
    height: auto;
  }
}
