// Primary colors
$white: #ffffff;
$blue-dark: #0a416e;
$blue-light: #2dc0d0;
$green: #55dc8c;
$light-green: #04f804;
$dark-grey: #495057;
$grey: #555;
$light-grey: #888888;
$red: #eb5a64;

// Secondary colors
$red: red;
$blue-light-transparent: rgba(45, 192, 208, 0.85);
$black: #000;
// Button colors
$hover-button-dark-blue: #06253f;
$hover-button-grey: #e6e6e6;
$hover-button-green: #2bd370;
$button-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
$toggle-switch-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
$leads-blue-border-color: #3f87c2;
// Date Picker
$date-picker-border: #c5c5c5;
$date-picker-day-background: #f6f6f6;
$date-picker-color: #454545;
$date-picker-today-background-color: #007fff;
$date-picker-today-border-color: #003eff;

// Input colors
$input-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
$grey-border: #aaa;
$active-outline: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

// Icon colors
$icon-grey: #ddd;

// Modal
$modal-border: rgba(0, 0, 0, 0.2);
$separate-line: rgba(0, 0, 0, 0.1);
$choose-file: #f1f1f1;
$choose-file-background: rgb(118, 118, 118);
