@import '~global/styles';

.comparisonPriceWrap {
  box-sizing: border-box;

  @include respond-to('large') {
    max-width: 100%;
    div > div:nth-of-type(2) > div > div {
      width: 30px;
    }
  }

  .comparisonPrice {
    padding: 23px;
    margin-bottom: 10px;
    height: calc(33.33% - 7px);
    @include respond-to('large') {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      padding: 15px;
    }
    .price {
      @include respond-to('large') {
        display: flex;
        justify-content: flex-end;
        font-size: $font-size-medium-secondary;
      }
    }

    p {
      margin: 0px;
    }
    .priceLoading {
      line-height: 30px;
    }

    div:nth-of-type(2) {
      display: flex;
    }
  }
  .currency {
    padding-left: 5px;
  }
}

.errorMessage {
  padding-right: 5px;
}
