@import '~global/styles';

.dropDownWrap {
  padding-bottom: 10px;
  select {
    font-family: $text-font;
    font-size: $font-size-medium;
    color: $grey;

    width: 100%;
    height: 40px;
    background-color: $white;
    margin: 0;
    padding: 5px;

    border: 2px solid $blue-dark;
    border-radius: 0.25em;
    box-shadow: $input-shadow;
    outline: none;

    .emptyOption {
      display: none;
    }
  }

  .text {
    margin-bottom: 8px;
    .required {
      color: $red;
      margin-left: 3px;
    }
  }
  .error {
    border: 2px solid $red;

    &:outline {
      box-shadow: none;
    }
  }
  &Center {
    text-align: center;
  }
}
