@import '~global/styles';

.radioButtonWrap {
  display: flex;

  @media (max-width: 820px) {
    margin-bottom: 20px;
  }

  label {
    margin-left: 5px;
  }
}

.ipadRadioButton {
  input,
  input[type='radio'] {
    min-width: 30px;
  }
}
