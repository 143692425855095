@import '~global/styles';

.columnWrapper {
  p:first-of-type {
    margin-bottom: 0;
  }
  p:last-of-type {
    margin-top: 0;
  }
  p {
    margin: 0;
  }
}
