@import '~global/styles';

.iFrameWrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 27.25%;

  @media (max-width: 992px) {
    padding-top: 56.25%;
  }
}
.iFrameResponsive {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
