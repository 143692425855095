@import '~global/styles';

.bottomButtonsWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  div:first-of-type {
    padding-right: 5px;
  }
}
