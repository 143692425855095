@import '~global/styles';
@import '../../styles.module.scss';

.dropDownWrap {
  display: flex;
  flex-direction: inline;
  align-items: center;

  padding: 10px 0;

  .agentIdSelect {
    padding-right: 10px;
  }
  > div:nth-of-type(2) {
    width: 63%;
    @media (max-width: 992px) {
      width: 40%;
    }
  }
}
.agentId {
  padding: 10px 0;
}

.toggleWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.toggleLabel {
  display: flex;
  flex-direction: row;
  align-items: center;

  a.terms {
    text-decoration: none;
    color: $blue-light;
    padding-left: 3px;
    font-size: 20px;
    @media (max-width: 896px) {
      padding-left: 0;
    }
  }
}

.contractAgreementsWrap {
  margin: 30px 15px;

  .separateLine {
    margin: 16px 0;
    height: 1px;
    background-color: $separate-line;
  }
  .contractAgreements {
    margin-left: 30px;
    .agreementsTitle {
      padding-top: 30px;
      margin-bottom: 8px;
    }
    .agreementsOption {
      margin-bottom: 5px;
      label {
        line-height: 25px;
      }
    }
  }
}
.contractErrorMessage {
  text-align: center;
}
