@import '~global/styles';
.container {
  width: 100%;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: 576px) {
    max-width: 540px;
    box-sizing: border-box;
  }
  @media (min-width: 768px) {
    max-width: 720px;
    box-sizing: border-box;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    box-sizing: border-box;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
    box-sizing: border-box;
  }
  @media (max-width: 576px) {
    padding: 0;
    box-sizing: border-box;
  }
}
.brandLogoContainer {
  padding: 30px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to('medium') {
    flex-direction: column;
    justify-content: center;

    img {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;

    img {
      margin-bottom: 30px;
    }
  }
}

.bottomButton {
  padding-bottom: 50px;
  div {
    margin: 20px 0;
  }
}
