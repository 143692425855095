.imglogo {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.imglogo img {
  width: 50%;
  height: auto;
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .imglogo img {
    width: 60%;
  }
}

/* For iPads in landscape mode */
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .imglogo img {
    width: 40%;
  }
}
h1 {
  text-align: center;
}

.app {
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 15px;
  /* width: 85%;*/
  max-width: 800px;
  margin: 30px auto;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.question-section {
  margin-bottom: 20px;
}

.question-count {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #888;
}

.question-text {
  font-size: 2em;
  margin-bottom: 20px;
}

.answer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.answer-section button {
  display: block;
  width: 70%;
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 1.8em;
  color: #444;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.answer-section button:hover {
  background-color: #f3f3f3;
}

.score-section {
  font-size: 2em;
  text-align: center;
  color: #444;
}

/* Responsivitet för mindre enheter */
@media screen and (max-width: 768px) {
  .app {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 480px) {
  .app {
    font-size: 0.6em;
  }
}

.quizbtn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.quizbtn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
