@import '~global/styles';

.minimizedModal {
  position: fixed;
  z-index: 3;
  bottom: 20px;
  left: 40px;
  padding: 10px;

  background-color: $blue-dark;
  border: 2px solid $blue-light;
  border-radius: 5px;

  display: flex;
  justify-content: space-between;

  .openButton,
  .closeButton {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }
  svg {
    color: $white;
    padding-right: 5px;
  }

  .closeButton {
    padding-left: 15px;
  }
}
