@import '~global/styles';
@import '../../styles.module.scss';

.textWrap {
  padding-bottom: 20px;
}

.lowScoreWrap {
  padding-top: 10px;
}
.fullmaktButton {
  display: inline-block;
  padding: 10px 12px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border: 2px solid #007bff;
  border-radius: 5px;
  font-family: 'enzoWeb-Bold';
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
}

.fullmaktButton:hover {
  background-color: white;
  color: #007bff;
}

.buttonSection {
  margin: 17px;
}

.errorMessage {
  margin: 17px;
  color: red;
}

.loaderContainer {
  display: flex;
  align-items: center;
}

.loaderText {
  margin-right: 8px;
  font-size: 16px;
}

.successButton {
  display: inline-block;
  padding: 10px 12px;
  background-color: #28a745;
  color: white;
  border: 2px solid #28a745;
  border-radius: 5px;
  font-family: 'enzoWeb-Bold';
  text-transform: uppercase;
}

.successButton:hover {
  background-color: white;
  color: #28a745;
}
