@import '~global/styles';
@import '../MediaMarkt/styles.module.scss';

.agreementsWrap {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  padding-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0 15px 30px 15px;
  }

  .infoSection {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding: 0 15px;
    box-sizing: border-box;

    @media (max-width: 575px) {
      padding: 0;
    }
  }
  .agreementImage {
    flex-basis: 100%;
    padding: 0 15px;

    @media (max-width: 575px) {
      padding: 0;
    }
    @media (min-width: 768px) and (max-width: 992px) {
      text-align: center;
    }
  }
}
