@import '~global/styles';

.areaChange {
  .areaText {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    margin: 10px 0 30px;
    gap: 2px;
  }

  .areaCode {
    display: inline-block;
    cursor: pointer;
    color: $blue-light;
    text-decoration: none;
    padding-left: 5px;
    font-size: $font-size-large;

    &Wrap {
      display: flex;
      flex-direction: row;
    }
  }
  .areaChangeInputWrap {
    width: 33%;
    border: 1px;

    input {
      min-width: 420px;
      min-height: 40px;
    }
  }
}
